
<template>
  <v-container fluid>
    <v-dialog class="ips-dialog" v-model="editing" hide-overlay transition="dialog-bottom-transition"   @click:outside="closeDialog"
              @keydown.esc="closeDialog">
      <v-card>
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- DATA -->
        <message v-model="editingItem"/>
      </v-card>
    </v-dialog>



    <v-card style="border:0; border-bottom: 1px solid #dedede;">
      <v-card-title>Filters</v-card-title>
      <v-card-text style="clear: both;display: block; overflow: hidden; margin:0 -12px;">

        <template v-for="(header, index) in calculateFilters">
          <div v-if="header.value !== 'ingressDate'"
               :style="{float: 'left', width: header.width, padding: '10px', boxSizing: 'border-box'}" >
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <v-autocomplete :items="flowItemNames"
                              :placeholder="calculatePlaceholder(header.text,'')"
                              :label="calculatePlaceholder(header.text,'')"
                              v-model="filterItems[`${header.value}`]"
                              @change="onFlowItemSelected"
                              @update:search-input="fetchFlowItems" outlined
                              clearable class="mr-1"></v-autocomplete>
            </div>
          </div>
          <div v-else-if="header.value === 'ingressDate' "
               :style="{float: 'left', width: header.width, padding: '10px', boxSizing: 'border-box'}">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <v-menu v-model="itemMenu[`${header.value}From`]" :close-on-content-click="false"
                      transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-bind="attrs" v-on="on" :placeholder="calculatePlaceholder(header.value,'From')"
                                :label="calculatePlaceholder(header.value,'From')"
                                v-model="filterItems[`${header.value}From`]" readonly outlined dense hide-details
                                clearable class="mr-1"></v-text-field>
                </template>
                <datetimepicker v-model="filterItems[`${header.value}From`]"
                                @input="saveDate(`${header.value}From`)" no-title clearable></datetimepicker>
              </v-menu>
              <v-menu v-model="itemMenu[`${header.value}To`]" :close-on-content-click="false"
                      transition="scale-transition" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-bind="attrs" v-on="on" :placeholder="calculatePlaceholder(header.value,'To')"
                                :label="calculatePlaceholder(header.value,'To')" v-model="filterItems[`${header.value}To`]"
                                readonly outlined dense hide-details clearable></v-text-field>
                </template>
                <datetimepicker v-model="filterItems[`${header.value}To`]" @input="saveDate(`${header.value}To`)"
                                no-title clearable></datetimepicker>
              </v-menu>
            </div>
          </div>
        </template>
        <div style="float: right; padding: 12px 0;">
          <v-btn depressed rounded color="primary" @click="buildFilterQueryString"  class="mr-2" medium><v-icon>mdi-magnify</v-icon>Search</v-btn>
          <v-btn depressed rounded color="error" @click="clearFilterQueryString" class="mr-2" medium> Reset</v-btn>
          <v-menu offset-y class="mr-2">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="secondary" v-bind="attrs" v-on="on">
                <v-icon>mdi-download</v-icon>
                {{ downloadLabel }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                  v-for="(format, index) in exportTypes"
                  :key="index"
                  @click="downloadFromQueryString(format)"
              >
                <v-list-item-title>{{ format.label }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

      </v-card-text>
    </v-card>

    <!--- HERE DATA --->
    <v-row >
      <v-col cols="12" class="mt-5">
        <v-data-table dense
                      :headers="calculateHeader"
                      :loading="loading"
                      :items="items" item-key="id" class="elevation-1"
                      :footer-props="itemsPerPageOptions"
                      :options.sync="options"
                      :server-items-length="totalCount"
                      :single-select="single" v-model="selected" show-select
        >
          <template #item.flowName="{ item }">
            <div class="text-truncate" style="max-width: 270px">
              {{ getMessageFlow(item) }}
            </div>
          </template>


          <template #item.lastErrorMessage="{ item }">
            <div
                class="text-truncate"
                :class="{ 'pointer-cursor': item.lastErrorMessage }"
                style="max-width: 80px"
                @click="showErrorMessage(item)"
            >
              {{ item.lastErrorMessage }}
            </div>
          </template>
          <template #item.messageId="{ item }">
            <div class="text-truncate" style="max-width: 270px">
              {{ item.messageId }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.messageId)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <template #item.correlationValue="{ item }">
            <div class="text-truncate" style="max-width: 270px" v-if="item.correlationValue">
              {{ item.correlationValue }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.correlationValue)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>

          </template>
          <template #item.topic="{ item }">
            <div class="text-truncate" style="max-width: 350px">
              {{ item.topic }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.topic)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <template #item.actions="{ item }">
            <div style="width: 100px;">
              <v-icon @click="edit(item)"  style="float: right;">mdi-pencil</v-icon>
              <v-spacer></v-spacer>
              <v-icon @click="logs(item)" style="margin-left: 10px;float: right;">
                mdi-tray-full</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>


  </v-container>
</template>

<script>
import message from "../shared/message.vue";
import datetimepicker from "../shared/DatetimePicker.vue";
import queue from "@/components/queue/queue.vue";

export default {
  name: "errors",
  components: {
    queue,
    message: message,
    datetimepicker : datetimepicker
  },
  methods: {
    getMessageFlow(item) {
      let name = "";
      this.flowItems.forEach((flow, index) => {
        if(flow.id === item.flowId){
          name = flow.name
        }
      })
      return name;
    },
    downloadFromQueryString: function(type) {
      let messageList;
      var queryParams = {
        downloadType: type.type
      }

      if(this.filterItems.ingressDateTo != null){
        queryParams["spanEnd"] = this.ConvertDateInJavascriptReadableFormat(this.filterItems.ingressDateTo);
      }

      if(this.filterItems.ingressDateFrom != null){
        queryParams["spanStart"] = this.ConvertDateInJavascriptReadableFormat(this.filterItems.ingressDateFrom);
      }

      if(this.filterItems.flowId != null){
        queryParams["flowId"] = this.filterItems.flowId;
      }

      var fileName = (this.filterItems.flowId) ? this.filterItems.flowId + "-" : "";

      var body = {
        PageSize: this.totalCount,
        RawQuery: "{}",
        PageNumber: 1,
        Sort: []
      }
      if(this.selected.length > 0){
        messageList = "[";

        this.selected.forEach((item, index) => {
          messageList += "\"" + item.messageId + "\""+ ((index !== this.selected.length - 1) ? "," : "")
        });

        messageList += "]"

        body = {
          PageSize: this.options.itemsPerPage,
          RawQuery: "{ \"MessageId\" : { \"$in\" : " + messageList + " } }",
          PageNumber: this.options.page,
          Sort: []
        }
      }

      return this.apiCallForErrorReportDownload(fileName + "error-report",`/api/Queue/error-report/download`,"POST", body, queryParams)
    },
    cancelEdit: function () {
      if (this.editingItem) {
        this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        this.editingItem.status = this.previousMessageStatusEdit;
        const index = this.items.findIndex(
            (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, { ...this.editingItem });
        }
      }
      this.editing = false;
    },
    closeDialog: function () {
      if (this.editing) {
        this.cancelEdit();
      }
    },
    handleBackButton : function (event) {
      location.reload();
    },
    async fetchFlowItems() {
      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";

      if (baseQuery.length > 1) {
        baseQuery += ",";
      }

      baseQuery += "}";
      this.query = {
        PageNumber: this.options.page,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      }
      this.loading = false;

      let endpoint = "/api/editableflows/list";

      let response = await this.apiCall(
          endpoint,
          "POST",
          this.query,
          {resultType: "reduced"}
      );

      if (response != null && response.status == 200) {
        this.flowItems = response.data.payload.content;
        this.flowItemNames = response.data.payload.content.map(i => i.name);
        this.loading = false;
      }

    },
    onFlowItemSelected(itemName) {
      this.filterItems.flowId = this.flowItems.find(x => x.name === itemName).id;
    },
    saveDate: function(field) {
      this.itemMenu[field] = false;
    },
    filterSearch: async function () {
      this.options.page = 1;
      await this.searchErrors();
    },
    searchSpecificErrorItem: async function (item) {
      const msgId = item.messageId;
      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";

      if (baseQuery.length > 1) {
        baseQuery += ",";
      }
      baseQuery += `"MessageId":"${msgId}"`;
      baseQuery += "}";
      this.loading = true;
      this.query = {
        PageNumber: 1,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      };


      if (this.options.sortBy) {
        this.query["Sort"] = [];
        for (var i = 0; i < this.options.sortBy.length; i++) {
          this.query["Sort"].push({
            Field: this.options.sortBy[i],
            Ascending: !this.options.sortDesc[i],
          });
        }
      }
      let endpoint = "/api/Queue/search-finalstatus";
      let response = await this.apiCall(
          endpoint,
          "POST",
          this.query,
          {}
      );
      if (response != null && response.status == 200) {
        const item = response.data.Data[0];
        if (item.ingressDate) {
          item.ingressDate = this.parseDateWithMoment(item.ingressDate);
        }
        if (item.lastElaborationDate) {
          item.lastElaborationDate = this.parseDateWithMoment(item.lastElaborationDate);
        }
        this.loading = false;
        return item;
      }
    },
    searchErrors: async function () {
      this.clearSelected();

      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";

      if (baseQuery.length > 1) {
        baseQuery += ",";
      }

      baseQuery += "}";
      this.loading = true;
      this.query = {
        PageNumber: this.options.page,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      };
      if (this.options.sortBy) {
        this.query["Sort"] = [];
        for (var i = 0; i < this.options.sortBy.length; i++) {
          this.query["Sort"].push({
            Field: this.options.sortBy[i],
            Ascending: !this.options.sortDesc[i],
          });
        }
      }

      let endpoint = "/api/Queue/error-report"; // TODO: change m

      var queryParams = {}

      if (this.filterItems.ingressDateTo != null) {
        queryParams["spanEnd"] = this.ConvertDateInJavascriptReadableFormat(this.filterItems.ingressDateTo);
      }

      if (this.filterItems.ingressDateFrom != null) {
        queryParams["spanStart"] = this.ConvertDateInJavascriptReadableFormat(this.filterItems.ingressDateFrom);
      }

      if (this.filterItems.flowId != null) {
        queryParams["flowId"] = this.filterItems.flowId;
        console.log(this.filterItems.flowId)
      }

      let response = await this.apiCall(
          endpoint,
          "POST",
          this.query,
          queryParams
      );


      if (response != null && response.status == 200) {
        this.items = response.data.payload.content.Data;
        this.totalCount = response.data.payload.content.TotalCount;
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.ingressDate) {
            item.ingressDate = this.parseDateWithMoment(item.ingressDate);
          }
          if (item.lastElaborationDate) {
            item.lastElaborationDate = this.parseDateWithMoment(item.lastElaborationDate);
          }
        }
        this.loading = false;
      }
    },
    clearSelected: function () {
      this.selected = [];
    },
    clearFilterQueryString: async function () {
      this.clearFilterItems();
      this.queryTxt = ""
      this.queryParams = {
        spanStart: "",
        spanEnd: "",
        flowId: "",
        formatType: "json"
      }
      history.pushState(null, '', window.location.pathname);
      await this.searchErrors();
    },
    filterDatetimeBuilder: function (errorMessage, filters, dateFrom, dateTo, datefield) {
      if (dateFrom && dateTo) {
        const lastElaborationDateToConv = this.ConvertDateInJavascriptReadableFormat(dateTo);
        const lastElaborationDateFromConv = this.ConvertDateInJavascriptReadableFormat(dateFrom);
        if (new Date(lastElaborationDateToConv) < new Date(lastElaborationDateFromConv)) {
          errorMessage = `Error: ${datefield}To is less recent than ${datefield}From.`;
        }
      }

      return errorMessage;
    },
    buildFilterQueryString: async function () {
      this.queryTxt = " $and: [";
      let errorMessage = '';
      let filters = [];

      this.trimFilterTextField();

      if (filters.length === 0) {
        this.queryTxt = "";
      } else {
        this.queryTxt += filters.join(", ");
        this.queryTxt += "] ";
        this.queryTxt = this.queryTxt;
      }

      if (errorMessage) {
        this.showError(errorMessage);
      } else {
        await this.filterSearch();
        // this.updateURLWithFilters();
        this.updateURLWithFiltersEncoded();
      }
    },
    ConvertDateInJavascriptReadableFormat: function (dateString) {
      const parts = dateString.split(/[/ :]/);
      return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
    },
    clearFilterItems: function () {
      this.filterItems = {
        messageId: null,
        flowId: null,
        ingressDateFrom: null,
        ingressDateTo: null,
        lastElaborationDateFrom: null,
        lastElaborationDateTo: null,
        attempts: null,
        lastErrorMessage: null,
        status: null,
        correlationValue: null,
        correlationPriorityField: null,
        topic: null,
      }
    },
    trimFilterTextField: function () {
      for (let key in this.filterItems) {
        if (this.filterItems[key] &&
            key !== 'ingressDateFrom' &&
            key !== 'ingressDateTo' &&
            key !== 'lastElaborationDateFrom' &&
            key !== 'lastElaborationDateTo' &&
            key !== "status") {
          this.filterItems[key] = this.filterItems[key].trim();
        }
      }
    },
    updateURLWithFiltersEncoded: function () {
      const queryString = this.buildUrlfromhFiltersSelectedwithBase64();
      let newUrl = `${window.location.pathname}`;
      if (queryString) {
        newUrl = `${newUrl}?${queryString}`;
      }
      history.pushState(null, '', newUrl);
    },
    buildUrlfromhFiltersSelectedwithBase64: function () {
      const params = new URLSearchParams();
      const filteredItems = {};
      let hasPopulatedProperty = false;
      for (const key in this.filterItems) {
        if (this.filterItems[key]) {
          filteredItems[key] = this.filterItems[key];
          hasPopulatedProperty = true;
        }
      }
      if (!hasPopulatedProperty) {
        return null;
      }

      filteredItems["queueType"] = this.inProgressQueue ? "global" : "tenant";

      const jsonString = JSON.stringify(filteredItems);
      const base64String = btoa(jsonString);
      params.append("filter", base64String);
      return params.toString();
    },
    showErrorMessage: async function (item) {
      this.viewing = true;
      this.viewingMessage = item;
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value)
          .then(() => {
            this.showSuccess('Copied to clipboard');
          })
          .catch(() => {
            this.showError('Failed to copy to clipboard');
          });
    },
    save: async function (data) {
      this.showSaving("Updating message data...");
      let msgBody = this.editingItem.messageData.msg;
      let isUpdateMsgOk = false;
      let isUpdateStatusOk = false;
      try {
        msgBody = JSON.parse(msgBody);
        let response = await this.apiCall(
            `/api/Queue/${this.editingItem.id}/messageBody`,
            "POST",
            msgBody,
            {}
        );
        if (response.status == 200) {
          isUpdateMsgOk = true;
        } else {
          this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        }
        if (this.editingItem.status == null) {
          this.showError("Error - Status is empty");
        } else {
          if (this.previousMessageStatusEdit !== this.editingItem.status) {
            const idsSelected = [this.editingItem.id];
            const body = {
              messageIds: idsSelected,
              status:
                  this.editingItem.status.charAt(0).toUpperCase() +
                  this.editingItem.status.slice(1),
            };
            let statusResponse = await this.apiCall(
                "/api/Queue/changeStatus",
                "POST",
                JSON.stringify(body),
                {}
            );
            if (
                statusResponse.status == 200 &&
                statusResponse.data.payload.content.status === "OK"
            ) {
              isUpdateStatusOk = true;
            } else {
              this.editingItem.status = this.previousMessageStatusEdit;
            }
          } else {
            isUpdateStatusOk = true;
          }
        }

        if (isUpdateMsgOk && isUpdateStatusOk) {
          this.showSuccess("Data successfully updated");
          //await this.searchQueue();
        }
        const index = this.items.findIndex(
            (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, {...this.editingItem});
        }
      } catch (e) {
        this.showError(`Error in update message data ${e}`);
        this.editingItem.status = this.previousMessageStatusEdit;
        this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        const index = this.items.findIndex(
            (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, {...this.editingItem});
        }
      } finally {
        this.editing = false;
        //TODO: To Enable this clear we have to check null values on jsonforms message creation
        //this.editingItem = null;
      }
    },
    logs: async function (item) {
      this.$emit("search-logs", item);
    },
    edit: async function (item) {
      const extendedItem = await this.searchSpecificErrorItem(item);
      this.editingItem = {};
      this.editingItem = extendedItem;
      this.code = JSON.stringify(extendedItem, null, 2);
      this.previousMessageStatusEdit = extendedItem.status;
      this.previousMessageBodyEdit = this.editingItem.messageData.msg;
      this.editing = true;
    },
  },
  computed: {
    calculatePlaceholder() {
      return function (value, type) {
        if (value === 'ingressDate') {
          return `Error ${type}`;
        } else {
          return `${value}`;
        }
      };
    },
    calculateFilters: function () {
      //console.log(filters);
      return [
        {text: "Flow Name", value: "flowName", width: "450px"},
        {text: "Error Date", value: "ingressDate", width: "450px"}
      ];
    },
    calculateHeader: function () {
      const actions =
          {
            text: "",
            value: "actions",
            active: true,
            sortable: false,
            width: "100px",
            class: "px-0",
          };
      if ( /*this.flowId &&*/ this.correlationPath && this.correlationPriorityPath) {
        return [...this.headers,
          {text: 'Correlation Value', value: 'correlationValue'},
          {text: 'Correlation Priority Value', value: 'correlationPriorityField'},
          actions];
      } else if (/*this.flowId &&*/ this.correlationPath && !this.correlationPriorityPath) {
        return [...this.headers,
          {text: 'Correlation Value', value: 'correlationValue'},
          actions];
      } else if (/*this.flowId &&*/ !this.correlationPath && this.correlationPriorityPath) {
        return [...this.headers,
          {text: 'Correlation Priority Value', value: 'correlationPriorityField'},
          actions];
      } else if (/*this.flowId &&*/ !this.correlationPath && !this.correlationPriorityPath) {
        return [...this.headers,
          actions];
      } else {
        return [...this.headers,
          {text: "Topic", value: "topic"},
          {
            text: "",
            value: "actions",
            active: true,
            sortable: false,
            width: "100px",
            class: "px-0",
          },];
      }
    },
    downloadLabel() {
      if (this.selected.length > 0)
        return "SELECTED";
      return "ALL"
    },
    calculateTemplates() {
      const baseTemplate = [
        {
          title: "Between date(Last Elaboration)",
          query:
              '$and:[{"LastElaborationDate":{ $gte: new Date("2022-12-01")}},{"LastElaborationDate":{ $lte : new Date("2023-12-01")}}]',
        },
        {
          title: "Between date(ProcessableSince)",
          query:
              '$and:[{"ProcessableSince":{ $gte: new Date("2022-12-01")}},{"ProcessableSince":{ $lte : new Date("2023-12-01")}}]',
        },
        {
          title: "By attempts (Equal)",
          query: '"Attempts":3',
        },
        {
          title: "By attempts (between)",
          query: '$and:[{"Attempts":{ $gte: 1}},{"Attempts":{ $lte : 3}}]',
        },
        {
          title: "By Status (equal)",
          query: '"Status":2',
        },
        {
          title: "By Message (equal)",
          query: '"MessageId":"messageid"',
        },
      ]
      if (!this.flowId) {
        baseTemplate.push({
          title: "By Topic (equal)",
          query: '"Topic":"topicid"',
        },)
      } else {
        baseTemplate.push(
            {
              title: "By Correlation Value (equal)",
              query: '"CorrelationValue":"correlationValueExample"',
            }
        )
      }
      return baseTemplate;
    },
  },
  props: {
    flowId: {
      type: String,
      required: false,
    },
    correlationPath: {
      type: String,
      required: false
    },
    correlationPriorityPath: {
      type: String,
      required: false
    },
    inProgressQueue: true
  },
  watch: {
    options: {
      handler() {
        this.searchErrors();
      },
      deep: true,
    },
  },
  data: () => ({
    now: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
    items: [],
    flowItems: [],
    flowItemNames: [],
    totalCount: 0,
    single: false,
    filterItems: {
      flowId: null,
      messageId: null,
      ingressDateFrom:  new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString() + " " + new Date().toLocaleTimeString(),
      ingressDateTo: new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString(),
      lastElaborationDateFrom: null,
      lastElaborationDateTo: null,
      lastErrorMessage: null,
      status: null,
      correlationValue: null,
    },
    itemMenu: {
      ingressDateFrom: false,
      ingressDateTo: false
    },
    itemsPerPageOptions: {"items-per-page-options": [15, 30, 50, 100, 200]},
    loading: false,
    filterStatusItem: [],
    selected: [],
    queryTxt: "",
    options: {itemsPerPage: 50, page: 1, sortBy: ["ingressDate"], sortDesc: [true]},
    datePickerProps: {
      'active-picker.sync': 'activePicker',
      max: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
      min: '1950-01-01',
    },
    headers: [
      {
        text: "Message Id",
        align: "start",
        sortable: false,
        value: "messageId",
      },
      {text: "Flow Name", value: "flowName", width: "120px"},
      {text: "Attempts", value: "attempts", width: "150px"},
      {text: "Last Error Message", value: "lastErrorMessage"},
      {text: "Error Date", value: "ingressDate"},
      {text: "Last Elaboration", value: "lastElaborationDate"}
    ],
    editingItem: {},
    editing: false,
    previousMessageStatusEdit: null,
    previousMessageBodyEdit: null,
    code: "",
    startTime: "",
    endTime: "",
    exportTypes: [
      {label: "JSON", type: "json"},
      {label: "CSV", type: "csv"}
    ]
  }),
  mounted: function () {
    window.addEventListener('popstate', this.handleBackButton);
    this.searchErrors();
  }
}
</script>


<style scoped>

</style>