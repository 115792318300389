<template>
  <v-container fluid>
    <v-dialog class="ips-dialog" v-model="editing" hide-overlay transition="dialog-bottom-transition"   @click:outside="closeDialog" 
    @keydown.esc="closeDialog">
      <v-card>
        <v-toolbar dark color="grey darken-4">
          <v-btn icon dark @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="save">Save</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- DATA -->
        <message v-model="editingItem" @input-change="handleInputChange" />
      </v-card>
    </v-dialog>

    <v-dialog class="ips-dialog" v-model="viewing">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Last Error Message
      </v-card-title>
      <v-card-text>
        <!-- Usa v-html per renderizzare HTML -->
        <div v-html="this.formatErrorMessage(viewingMessage.lastErrorMessage)" class="error-message"></div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined rounded depressed color="primary" text @click="viewing = false">
          CLOSE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
      <v-card class="d-none">
        <v-card-title>Advanced Filters</v-card-title>
        <v-card-text>
          <v-row class="d-flex align-center ips-filter-search">
            <v-col cols="11">
              <v-text-field v-model="queryTxt" v-on:keyup.enter="filterSearch()">
                <v-menu slot="prepend">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in calculateTemplates" :key="index">
                      <v-list-item-title>
                        <v-card @click="suggestQuery(item)">
                          <v-card-title>{{ item.title }}</v-card-title>
                          <v-card-subtitle>{{
                            item.description ?? item.query
                          }}</v-card-subtitle>
                        </v-card>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-text-field>
            </v-col>
            <v-col cols="1" class="d-flex align-center">
              <v-btn depressed rounded color="primary" @click="filterSearch()"  class="mr-2" small><v-icon>mdi-magnify</v-icon></v-btn>
              <v-btn depressed rounded color="error" @click="clearSearch()" small>
                <v-icon>mdi-close-circle</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card style="border:0; border-bottom: 1px solid #dedede;">
        <v-card-title>Filters</v-card-title>
        <v-card-text style="clear: both;display: block; overflow: hidden; margin:0 -12px;">

          <template v-for="(header, index) in calculateFilters">
            <div v-if="header.value !== 'ingressDate' && header.value !== 'lastElaborationDate' && header.value !== 'status'"
                 :style="{float: 'left', width: header.width, padding: '10px', boxSizing: 'border-box'}" >
              <v-text-field :placeholder="header.text" :label="header.text" v-model="filterItems[header.value]"
                            outlined dense hide-details clearable></v-text-field>
            </div>
            <div v-else-if="header.value === 'ingressDate' || header.value === 'lastElaborationDate'"
                 :style="{float: 'left', width: header.width, padding: '10px', boxSizing: 'border-box'}">
              <div style="display: flex;justify-content: space-between;align-items: center;">
                <v-menu v-model="itemMenu[`${header.value}From`]" :close-on-content-click="false"
                        transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-bind="attrs" v-on="on" :placeholder="calculatePlaceholder(header.value,'From')"
                                  :label="calculatePlaceholder(header.value,'From')"
                                  v-model="filterItems[`${header.value}From`]" readonly outlined dense hide-details
                                  clearable class="mr-1"></v-text-field>
                  </template>
                  <datetimepicker v-model="filterItems[`${header.value}From`]"
                                  @input="saveDate(`${header.value}From`)" no-title clearable></datetimepicker>
                </v-menu>
                <v-menu v-model="itemMenu[`${header.value}To`]" :close-on-content-click="false"
                        transition="scale-transition" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-bind="attrs" v-on="on" :placeholder="calculatePlaceholder(header.value,'To')"
                                  :label="calculatePlaceholder(header.value,'To')" v-model="filterItems[`${header.value}To`]"
                                  readonly outlined dense hide-details clearable></v-text-field>
                  </template>
                  <datetimepicker v-model="filterItems[`${header.value}To`]" @input="saveDate(`${header.value}To`)"
                                  no-title clearable></datetimepicker>
                </v-menu>
              </div>
            </div>
            <div v-else-if="header.value === 'status'"
                 :style="{float: 'left', width: header.width, padding: '10px', boxSizing: 'border-box'}">
              <v-select :items="filterStatusItem"
                        v-model="filterItems[header.value]" :label="header.text" multiple outlined dense hide-details
                        clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1"><span>{{ item }}</span></v-chip>
                  <span v-if="index === 1" class="text-grey text-caption">
                    (+{{ filterItems[header.value].length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>

          </template>
          <div style="float: right; padding: 12px 0;">
            <v-btn depressed rounded color="primary" @click="buildFilterQueryString"  class="mr-2" medium><v-icon>mdi-magnify</v-icon>Search</v-btn>
            <v-btn depressed rounded color="error" @click="clearFilterQueryString" medium> Reset</v-btn>
          </div>

        </v-card-text>
      </v-card>
    <v-row >
      <v-col cols="12" class="mt-5">
        <v-data-table dense
          :headers="calculateHeader"
          :loading="loading"
          :items="items" item-key="id" class="elevation-1"
          :footer-props="itemsPerPageOptions"
          :options.sync="options"
          :server-items-length="totalCount"
          :single-select="single" v-model="selected" show-select
        >
          <template v-if="userService.canWriteMessageInfos()" v-slot:top>
            <v-row class="px-4 d-flex">
              <v-col cols="2">
                <v-select
                  :items="statuses"
                  label="status"
                  dense
                  outlined
                  v-model="statusSelected"
                  @change="handleChangeStatus"
                ></v-select>
              </v-col>
              <v-col cols="1">
                <v-btn
                  outlined
                  :disabled="selected.length == 0"
                  @click="changeStatusForSelected"
                >
                Change</v-btn
                >
              </v-col>
            </v-row>
          </template>
          <template #item.lastErrorMessage="{ item }">
            <div
              class="text-truncate"
              :class="{ 'pointer-cursor': item.lastErrorMessage }"
              style="max-width: 80px"
              @click="showErrorMessage(item)"
            >
              {{ item.lastErrorMessage }}
            </div>
          </template>
          <template #item.messageId="{ item }">
            <div class="text-truncate" style="max-width: 270px">
              {{ item.messageId }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.messageId)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <template #item.correlationValue="{ item }">
            <div class="text-truncate" style="max-width: 270px" v-if="item.correlationValue">
              {{ item.correlationValue }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.correlationValue)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>

          </template>
          <template #item.topic="{ item }">
            <div class="text-truncate" style="max-width: 350px">
              {{ item.topic }}
              <v-btn v-show="item" icon @click="copyToClipboard(item.topic)">
                <v-icon small>mdi-content-copy</v-icon>
              </v-btn>
            </div>
          </template>
          <template #item.actions="{ item }">
            <div style="width: 100px;">
              <v-icon v-if="userService.canWriteMessageInfos()" @click="edit(item)"  style="float: right;">mdi-pencil</v-icon>
              <v-spacer></v-spacer>
              <v-icon @click="logs(item)" style="margin-left: 10px;float: right;"
                      v-if="userService.canReadFlowlogs() && (flowId != undefined && flowId != '' && item != undefined && (item.status == 'error' || item.status == 'aborted'))">
                mdi-tray-full</v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import message from "../shared/message.vue";
import datetimepicker from "../shared/DatetimePicker.vue";
import userService from "@/services/user";
export default {
  name: "Queue",
  components: {
    message: message,
    datetimepicker : datetimepicker
  },
  beforeMount: async function () {
    this.statuses = ["Success", "New", "Error","Ignored"];
    if(this.inProgressQueue) {
      this.filterStatusItem = ["new", "processing", "sent"];
    }
    else {
      this.filterStatusItem = ["success", "error", "aborted","ignored"];
    }
    //this.applyFiltersFromURLOrSearchQueue();
    this.applyDecodedFiltersFromURLOrSearchQueue();
  },
  props: {
    flowId: {
      type: String,
      required: false,
    }, 
    correlationPath : {
      type : String,
      required : false
    },
    correlationPriorityPath : {
      type : String,
      required : false
    },
    inProgressQueue: true
  },
  watch: {
    options: {
      handler() {
        //console.log("search");
        this.searchQueue();
      },
      deep: true,
    },
  },
  computed : {
    userService: function(){
      return userService;
    },
    calculatePlaceholder() {
      return function(value, type) {
        if (value === 'ingressDate') {
          return `Creation ${type}`;
        } else if (value === 'lastElaborationDate') {
          return `Last Elaboration ${type}`;
        } else {
          return `${value} From`;
        }
      };
    },
    calculateFilters: function() {
      let filters = [
        { text: "Status", value: "status",  width: "300px" },
        { text: 'Correlation Value', value: 'correlationValue', width: "250px"},
        { text: "Creation Date", value: "ingressDate", width: "450px"},
        { text: "Last Elaboration", value: "lastElaborationDate", width: "450px"},
        { text: "Last Error Message", value: "lastErrorMessage", width: "400px"},
        { text: "Message Id", value: "messageId", width: "300px"}
      ];
      //console.log(filters);
      return filters;
    },
    calculateHeader : function() {
      const actions =
      {
        text: "",
        value: "actions",
        active: true,
        sortable: false,
        width: "100px",
        class: "px-0",
      };
      if ( this.flowId && this.correlationPath && this.correlationPriorityPath ) {
        return [...this.headers,
          { text: 'Correlation Value', value: 'correlationValue' },
          { text: 'Correlation Priority Value', value: 'correlationPriorityField' },
          actions];
      } else if (this.flowId && this.correlationPath && !this.correlationPriorityPath ) {
        return [...this.headers,
          { text: 'Correlation Value', value: 'correlationValue' },
          actions];
      } else if (this.flowId && !this.correlationPath  && this.correlationPriorityPath) {
        return [...this.headers,
          { text: 'Correlation Priority Value', value: 'correlationPriorityField' },
          actions];
      } else if ( this.flowId && !this.correlationPath && !this.correlationPriorityPath ) {
        return [...this.headers,
          actions];
      }
      else {
        return [...this.headers,
          { text: "Topic", value: "topic" },
          {
            text: "",
            value: "actions",
            active: true,
            sortable: false,
            width: "100px",
            class: "px-0",
          },];
      }
    },
    calculateTemplates() {
      const baseTemplate = [
        {
          title: "Between date(Last Elaboration)",
          query:
            '$and:[{"LastElaborationDate":{ $gte: new Date("2022-12-01")}},{"LastElaborationDate":{ $lte : new Date("2023-12-01")}}]',
        },
        {
          title: "Between date(ProcessableSince)",
          query:
            '$and:[{"ProcessableSince":{ $gte: new Date("2022-12-01")}},{"ProcessableSince":{ $lte : new Date("2023-12-01")}}]',
        },
        {
          title: "By attempts (Equal)",
          query: '"Attempts":3',
        },
        {
          title: "By attempts (between)",
          query: '$and:[{"Attempts":{ $gte: 1}},{"Attempts":{ $lte : 3}}]',
        },
        {
          title: "By Status (equal)",
          query: '"Status":2',
        },
        {
          title: "By Message (equal)",
          query: '"MessageId":"messageid"',
        },
      ]
      if (!this.flowId) {
        baseTemplate.push({
          title: "By Topic (equal)",
          query: '"Topic":"topicid"',
        },)
      } else {
        baseTemplate.push(
        {
          title: "By Correlation Value (equal)",
          query: '"CorrelationValue":"correlationValueExample"',
        }
      )
      }
      return baseTemplate;
    },
  },
  methods: {
    handleBackButton : function (event) {
      location.reload();
    },

    formatErrorMessage(message) {
    if (!message) return '';
    return message.replace(/\r?\n/g, '<br>');
    },   
    saveDate: function(field) {
      this.itemMenu[field] = false;
    },
    changeStatusForSelected: async function () {
      const idsSelected = [];
      this.selected.forEach((s) => {
        idsSelected.push(s.id);
      });
      if (!this.statusSelected) {
        this.showError("No status selected");
      } else {
        const body = {
          messageIds: idsSelected,
          status: this.statusSelected,
        };
        let response = await this.apiCall(
          "/api/Queue/changeStatus",
          "POST",
          JSON.stringify(body),
          {}
        );
        if (response.status == 200 && response.data.payload.content.status === "OK") {
          this.showSuccess("Status successfully updated for selected messages");
        } else if (response.status == 200 && response.data.payload.content.status === "WARNING") {
          this.showWarning("Failed to update status for all selected messages");
        }
      }
    },
    suggestQuery: async function (item) {
      if (this.queryTxt.length > 0) {
        this.queryTxt = this.queryTxt + "," + item.query;
      } else {
        this.queryTxt = item.query;
      }
    },
    showErrorMessage: async function (item) {
      this.viewing = true;
      this.viewingMessage = item;
    },
    handleChangeStatus() {
      //console.log("Elemento selezionato:", this.statusSelected);
    },
    buildFilterQueryString: async function() {
      this.queryTxt = " $and: [";
      let errorMessage = '';
      let filters = [];

      this.trimFilterTextField();

      if (this.filterItems.messageId) {
        filters.push(`{ MessageId: "${this.filterItems.messageId}" }`);
      }

      errorMessage = this.filterDatetimeBuilder(errorMessage,filters,this.filterItems.ingressDateFrom,this.filterItems.ingressDateTo,"IngressDate");
      errorMessage = this.filterDatetimeBuilder(errorMessage,filters,this.filterItems.lastElaborationDateFrom,this.filterItems.lastElaborationDateTo,"LastElaborationDate");
      if (this.filterItems.attempts) {
        const parsedAttempts = Number(this.filterItems.attempts);
        if (!isNaN(parsedAttempts)) {
          filters.push(`{ Attempts: ${parsedAttempts} }`);
        } else {
          errorMessage = "Invalid value for attempts";
        }
      }

      if (this.filterItems.lastErrorMessage) {
        filters.push(`{ LastErrorMessage: { $regex: "${this.filterItems.lastErrorMessage}", $options: "i" } }`);
      }

      if (this.filterItems.status && this.filterItems.status.length > 0) {
        if (this.filterItems.status.length === 1) {
          filters.push(`{ Status: ${this.statusMap[this.filterItems.status[0].toLowerCase()]} }`);
        } else {
          const orConditions = this.filterItems.status?.map(status => {
            return `{ Status: ${this.statusMap[status.toLowerCase()]} }`;}).join(', ');
          filters.push(`{ $or: [ ${orConditions} ] }`);
        }
      }

      if (this.filterItems.correlationValue) {
        const escapedValue = this.escapeForJson(this.escapeRegexChars(this.filterItems.correlationValue));
        filters.push(`{ CorrelationValue: { $regex: "${escapedValue}", $options: "i" } }`);
      }
      if (this.filterItems.correlationPriorityField) {
        filters.push(`{ CorrelationPriorityField: "${this.filterItems.correlationPriorityField}" }`);
      }

      if (this.filterItems.topic) {
        filters.push(`{ Topic: "${this.filterItems.topic}" }`);
      }

      if (filters.length === 0) {
        this.queryTxt = "";
      } else {
        this.queryTxt += filters.join(", ");
        this.queryTxt += "] ";
        this.queryTxt = this.queryTxt;
      }

      if (errorMessage) {
        this.showError(errorMessage);
      } else {
        await this.filterSearch();
        // this.updateURLWithFilters();
        this.updateURLWithFiltersEncoded();
      }
    },
    trimFilterTextField: function ()
    {
      for (let key in this.filterItems) {
        if (this.filterItems[key] &&
            key !== 'ingressDateFrom' &&
            key !== 'ingressDateTo' &&
            key !== 'lastElaborationDateFrom' &&
            key !== 'lastElaborationDateTo' &&
            key !== "status") {
            this.filterItems[key] = this.filterItems[key].trim();
          }
        }
    },
    escapeRegexChars : function (value) {
      return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');},
    escapeForJson :function escapeForJson(value) {
      return value.replace(/\\/g, '\\\\'); 
    },

    buildUrlFromFilterSelected: function ()
    {
      const params = new URLSearchParams();
        for (const key in this.filterItems) {
          if (this.filterItems[key]) {
            params.append(key, this.filterItems[key]);
          }
        }
        return params.toString();
    },
  
    buildUrlfromhFiltersSelectedwithBase64: function() {
      const params = new URLSearchParams();
      const filteredItems = {};
      let hasPopulatedProperty = false;
      for (const key in this.filterItems) {
          if (this.filterItems[key]) {
              filteredItems[key] = this.filterItems[key];
              hasPopulatedProperty = true;
          }
      }
      if (!hasPopulatedProperty) {
          return null;
      }

      filteredItems["queueType"] = this.inProgressQueue ? "global" : "tenant";

      const jsonString = JSON.stringify(filteredItems);
      const base64String = btoa(jsonString);
      params.append("filter", base64String);
      return params.toString();
    },

    updateURLWithFiltersEncoded: function () {
      const queryString = this.buildUrlfromhFiltersSelectedwithBase64();
      let newUrl = `${window.location.pathname}`;
      if (queryString) {
        newUrl = `${newUrl}?${queryString}`;
      }
      history.pushState(null, '', newUrl);
    },

    updateURLWithFilters: function () {
      const queryString = this.buildUrlFromFilterSelected();
      const newUrl = `${window.location.pathname}?${queryString}`;
      history.pushState(null, '', newUrl);
    },
    ExctractAndDecodeFiltersFromUrl: function (base64Filters) {
      const jsonString = atob(base64Filters);
      const combinedFilters = JSON.parse(jsonString);
      return combinedFilters;
    },

    clearFilterQueryString: async function () {
      this.clearFilterItems();
      history.pushState(null, '',window.location.pathname);
      await this.clearSearch();
    },
    clearFilterItems: function()
    {
      this.filterItems =  {
        messageId: null,
        ingressDateFrom: null,
        ingressDateTo: null,
        lastElaborationDateFrom: null,
        lastElaborationDateTo: null,
        attempts : null,
        lastErrorMessage : null,
        status : null,
        correlationValue : null,
        correlationPriorityField: null,
        topic: null,
      }
    },
    filterDatetimeBuilder: function (errorMessage,filters,dateFrom,dateTo,datefield)
    {
        if (dateFrom && dateTo) {
        const lastElaborationDateToConv = this.ConvertDateInJavascriptReadableFormat(dateTo);
        const lastElaborationDateFromConv = this.ConvertDateInJavascriptReadableFormat(dateFrom);
        if (new Date(lastElaborationDateToConv) < new Date(lastElaborationDateFromConv)) {
          errorMessage = `Error: ${datefield}To is less recent than ${datefield}From.`;
        } else {
          filters.push(`{ ${datefield}: { $gte: new Date("${lastElaborationDateFromConv.toISOString()}"), $lte: new Date("${lastElaborationDateToConv.toISOString()}") } }`);
        }
      } else if (dateFrom) {
        filters.push(`{ ${datefield}: { $gte: new Date("${this.ConvertDateInJavascriptReadableFormat(dateFrom).toISOString()}") } }`);
      } else if (dateTo) {
        filters.push(`{ ${datefield}: { $lte: new Date("${this.ConvertDateInJavascriptReadableFormat(dateTo).toISOString()}") } }`);
      }
      return errorMessage;
    },
    ConvertDateInJavascriptReadableFormat: function(dateString)
    {
      const parts = dateString.split(/[/ :]/);
      return new Date(parts[2], parts[1] - 1, parts[0], parts[3], parts[4]);
    },
    save: async function (data) {
      this.showSaving("Updating message data...");
      let msgBody = this.editingItem.messageData.msg;
      let isUpdateMsgOk = false;
      let isUpdateStatusOk = false;
      try {
        msgBody = JSON.parse(msgBody);
        let response = await this.apiCall(
          `/api/Queue/${this.editingItem.id}/messageBody`,
          "POST",
          msgBody,
          {}
        );
        if (response.status == 200) {
          isUpdateMsgOk = true;
        }
        else 
        {
          this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        }
        if (this.editingItem.status == null) {
          this.showError("Error - Status is empty");
        } 
        else {
            if (this.previousMessageStatusEdit !== this.editingItem.status) {
            const idsSelected = [this.editingItem.id];
            const body = {
              messageIds: idsSelected,
              status:
                  this.editingItem.status.charAt(0).toUpperCase() +
                  this.editingItem.status.slice(1),
            };
            let statusResponse = await this.apiCall(
                "/api/Queue/changeStatus",
                "POST",
                JSON.stringify(body),
                {}
            );
            if (
                statusResponse.status == 200 &&
                statusResponse.data.payload.content.status === "OK"
            ) {
              isUpdateStatusOk = true;
            }
            else {
              this.editingItem.status = this.previousMessageStatusEdit;
            }
          }
          else
          {
            isUpdateStatusOk = true;
          }
        }

        if (isUpdateMsgOk && isUpdateStatusOk) {
          this.showSuccess("Data successfully updated");
          //await this.searchQueue();
        }
        const index = this.items.findIndex(
        (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, { ...this.editingItem });
        }
      } catch (e) {
        this.showError(`Error in update message data ${e}`);
        this.editingItem.status = this.previousMessageStatusEdit;
        this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        const index = this.items.findIndex(
        (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, { ...this.editingItem });
        } 
      } finally
      {
        this.editing = false;
        //TODO: To Enable this clear we have to check null values on jsonforms message creation
        //this.editingItem = null;
      }
    },
    logs: async function (item) {
      this.$emit("search-logs", item);
    },
    edit: async function (item) {
      const extendedItem = await this.searchSpecificQueueItem(item);
      this.editingItem = {};
      this.editingItem = extendedItem;
      this.code = JSON.stringify(extendedItem, null, 2);
      this.previousMessageStatusEdit = extendedItem.status;
      this.previousMessageBodyEdit = this.editingItem.messageData.msg;
      this.editing = true;
    },
    filterSearch: async function () {
      this.options.page = 1;
      await this.searchQueue();
    },
    handleInputChange(newValue) {
    },
    cancelEdit: function () {
      if (this.editingItem) {
        this.editingItem.messageData.msg = this.previousMessageBodyEdit;
        this.editingItem.status = this.previousMessageStatusEdit;
        const index = this.items.findIndex(
        (item) => item.id === this.editingItem.id);
        if (index !== -1) {
          this.$set(this.items, index, { ...this.editingItem });
        }
      }
      this.editing = false;
    },
    closeDialog: function () {
      if (this.editing) {
        this.cancelEdit();
      }
  },
    copyToClipboard(value) {
    navigator.clipboard.writeText(value)
      .then(() => {
      this.showSuccess('Copied to clipboard');
      })
      .catch(() => {
        this.showError('Failed to copy to clipboard');
      });
    },
    handleQuerySearch: async function() {},
    clearSearch: async function () {
      this.clearSelected();
      this.queryTxt = "";
      await this.filterSearch();
    },
    handleBarChartFilterSearch: async function (query,dates,statuses,fieldDate) {
      this.queryTxt = query;
      this.clearFilterItems();
      this.filterItems.status = statuses;
      if (fieldDate === "IngressDate") 
      {
        this.filterItems.ingressDateFrom = dates[0];
        this.filterItems.ingressDateTo = dates[1];
      } else 
      {
        this.filterItems.lastElaborationDateFrom = dates[0];
        this.filterItems.lastElaborationDateTo = dates[1];
      }
      await this.filterSearch();
      this.updateURLWithFiltersEncoded();
    },
    applyFiltersFromURLOrSearchQueue: async function() {
      const urlParams = new URLSearchParams(window.location.search);
      for (const [key, value] of urlParams.entries()) {
        if (this.filterItems.hasOwnProperty(key)) {
          if (key === 'status') {
            this.filterItems[key] = value ? value.split(',') : [];
          } else {
            this.filterItems[key] = value || null;
          }
        }
      }
      await this.buildFilterQueryString();
    },

    applyDecodedFiltersFromURLOrSearchQueue: async function() {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const filters = urlParams.get('filter');
        if (filters) {
          const filtersDecoded = this.ExctractAndDecodeFiltersFromUrl(filters);

          var queueName = this.inProgressQueue ? "global" : "tenant";
          if(filtersDecoded["queueType"] == queueName) {
            for (const key in filtersDecoded) {
              if (this.filterItems.hasOwnProperty(key)) {
                this.filterItems[key] = filtersDecoded[key];
              }
            }
            await this.buildFilterQueryString();
          }
        }
      } catch (error) {
        await this.clearFilterQueryString();
      }
    },

    clearSelected: function(){
      this.selected = [];
    },

    searchSpecificQueueItem: async function(item) 
    {
      const msgId = item.messageId;
      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";
      if (baseQuery.length > 1) {
        baseQuery += ",";
      }
      baseQuery += `"MessageId":"${msgId}"`;
      baseQuery += "}";
      this.loading = true;
      this.query = {
        PageNumber: 1,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      };
      if (this.options.sortBy) {
        this.query["Sort"] = [];
        for (var i = 0; i < this.options.sortBy.length; i++) {
          this.query["Sort"].push({
            Field: this.options.sortBy[i],
            Ascending: !this.options.sortDesc[i],
          });
        }
      }
      let endpoint = this.inProgressQueue ? "/api/Queue/search-inprogress" : "/api/Queue/search-finalstatus";
      let response = await this.apiCall(
        endpoint,
        "POST",
        this.query,
        {}
      );
      if (response != null && response.status == 200) {
        const item = response.data.Data[0];
        if (item.ingressDate) {
          item.ingressDate = this.parseDateWithMoment(item.ingressDate);}
        if (item.lastElaborationDate) {
          item.lastElaborationDate = this.parseDateWithMoment(item.lastElaborationDate);}
        this.loading = false;
        return item;
      }
    },
    searchQueue: async function () {
      this.clearSelected();
      let baseQuery = "{";
      baseQuery += this.queryTxt && this.queryTxt != "" ? this.queryTxt : "";
      if (baseQuery.length > 1) {
        baseQuery += ",";
      }
      if (this.flowId && this.flowId.length > 0) {
        baseQuery += `"FlowId":"${this.flowId}"`;
      }
      baseQuery += "}";
      this.loading = true;
      this.query = {
        PageNumber: this.options.page,
        PageSize: this.options.itemsPerPage,
        RawQuery: baseQuery,
        Sort: [],
      };

      if (this.options.sortBy) {
        this.query["Sort"] = [];
        for (var i = 0; i < this.options.sortBy.length; i++) {
          this.query["Sort"].push({
            Field: this.options.sortBy[i],
            Ascending: !this.options.sortDesc[i],
          });
        }
      }

      let endpoint = this.inProgressQueue ? "/api/Queue/search-inprogress" : "/api/Queue/search-finalstatus";

      let response = await this.apiCall(
        endpoint,
        "POST",
        this.query,
        {resultType: "reduced"}
      );
      if (response != null && response.status == 200) {
        this.items = response.data.Data;
        this.totalCount = response.data.TotalCount;
        for (let i = 0; i < this.items.length; i++) {
          const item = this.items[i];
          if (item.ingressDate) {
            item.ingressDate = this.parseDateWithMoment(item.ingressDate);
          }
          if (item.lastElaborationDate) {
            item.lastElaborationDate = this.parseDateWithMoment(item.lastElaborationDate);
          }
        }
        this.loading = false;
      }
    },
  },
  mounted: function(){
    window.addEventListener('popstate', this.handleBackButton);
  },
  data: () => ({
    filterItems: {
      messageId: null,
      ingressDateFrom: null,
      ingressDateTo: null,
      lastElaborationDateFrom: null,
      lastElaborationDateTo: null,
      lastErrorMessage : null,
      status : null,
      correlationValue : null,
    },
    statusMap : {
        'new': 0,
        'processing': 1,
        'sent': 4,
        'success': 2,
        'error': 3,
        'aborted': 5,
        'ignored': 6
    },
    itemMenu: {
      ingressDateFrom: false,
      ingressDateTo: false,
      lastElaborationDateFrom: false,
      lastElaborationDateTo: false,
      },
      itemDate: {
        ingressDate: null,
        lastElaborationDate: null,
      },
    selected: [],
    statuses: [],
    filterStatusItem: [],
    statusSelected: "",
    single: false,
    templates: [
      {
        title: "Between date(Last Elaboration)",
        query:
          '$and:[{"LastElaborationDate":{ $gte: new Date("2022-12-01")}},{"LastElaborationDate":{ $lte : new Date("2023-12-01")}}]',
      },
      {
        title: "Between date(ProcessableSince)",
        query:
          '$and:[{"ProcessableSince":{ $gte: new Date("2022-12-01")}},{"ProcessableSince":{ $lte : new Date("2023-12-01")}}]',
      },
      {
        title: "By attempts (Equal)",
        query: '"Attempts":3',
      },
      {
        title: "By attempts (between)",
        query: '$and:[{"Attempts":{ $gte: 1}},{"Attempts":{ $lte : 3}}]',
      },
      {
        title: "By Status (equal)",
        query: '"Status":2',
      },
      {
        title: "By Topic (equal)",
        query: '"Topic":"topicid"',
      },
      {
        title: "By Message (equal)",
        query: '"MessageId":"topicid"',
      },
    ],
    editingItem: {},
    editing: false,
    previousMessageStatusEdit: null,
    previousMessageBodyEdit : null,
    code: "",
    itemsPerPageOptions: { "items-per-page-options": [15, 30, 50, 100, 200] },
    loading: false,
    editingTitle: "",
    viewing: false,
    viewingMessage: {},
    query: {},
    sort: {},
    page: 0,
    limit: 100,
    items: [],
    queryTxt: "",
      datePickerProps: {
        'active-picker.sync': 'activePicker',
        max: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substring(0, 10),
        min: '1950-01-01',
    },
    activePicker : false,
    pagination: { pageCount: 200 },
    options: { itemsPerPage: 50, page: 1, sortBy: ["ingressDate"], sortDesc: [true]},
    totalCount: 0,
    headers: [
      {
        text: "Message Id",
        align: "start",
        sortable: false,
        value: "messageId",
      },
      { text: "Status", value: "status",  width: "120px" },
      { text: "Attempts", value: "attempts", width: "150px" },
      { text: "Last Error Message", value: "lastErrorMessage"},
      { text: "Creation Date", value: "ingressDate" },
      { text: "Last Elaboration", value: "lastElaborationDate" },
     // { text: "MessageId", value: "messageId", width: "200px" },
     // { text: "Topic", value: "topic" },
     //  { text: "Processable Since", value: "processableSince", width: "200px" },
    //  {
    //     text: "_id",
    //     align: "start",
    //     sortable: false,
    //     value: "id",
    //   },
    ],
  }),
};
</script>

<style scoped>
.pointer-cursor {
  cursor: pointer;
}
.truncate-single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1; /* Imposta il numero di linee visibili prima di mostrare gli ellissi */
}
.ellipsis-placeholder::placeholder {
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
  .ellipsis-input input {
    overflow: hidden;
    text-overflow: ellipsis !important;
    white-space: nowrap;
  }
  .error-message {
  white-space: pre-line; 
  max-height: 300px;
  overflow-y: auto;
}
</style>

