console.log(process.env);
const settings = {
  local: {
    domain: process.env.VUE_APP_LOCAL_DOMAIN,
    client_id: process.env.VUE_APP_LOCAL_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_LOCAL_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_LOCAL_APIURL,
    userHubUrl: process.env.VUE_APP_LOCAL_USERHUBURL,
    egressUrl: process.env.VUE_APP_LOCAL_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_LOCAL_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_LOCAL_INGRESSWEB,
    audience: process.env.VUE_APP_LOCAL_AUDIENCE,
    scope: process.env.VUE_APP_LOCAL_SCOPE,
    assetsUrl: process.env.VUE_APP_LOCAL_ASSETSURL,
  },
  test: {
    domain: process.env.VUE_APP_TEST_DOMAIN,
    client_id: process.env.VUE_APP_TEST_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_TEST_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_TEST_APIURL,
    userHubUrl: process.env.VUE_APP_TEST_USERHUBURL,
    egressUrl: process.env.VUE_APP_TEST_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_TEST_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_TEST_INGRESSWEB,
    audience: process.env.VUE_APP_TEST_AUDIENCE,
    scope: process.env.VUE_APP_TEST_SCOPE,
    assetsUrl: process.env.VUE_APP_TEST_ASSETSURL,
  },
  test_oldipaas: {
    domain: process.env.VUE_APP_TEST_DOMAIN,
    client_id: process.env.VUE_APP_TEST_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_TESTOLDIPAAS_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_TESTOLDIPAAS_APIURL,
    userHubUrl: process.env.VUE_APP_TEST_USERHUBURL,
    egressUrl: process.env.VUE_APP_TESTOLDIPAAS_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_TESTOLDIPAAS_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_TESTOLDIPAAS_INGRESSWEB,
    audience: process.env.VUE_APP_TEST_AUDIENCE,
    scope: process.env.VUE_APP_TEST_SCOPE,
    assetsUrl: process.env.VUE_APP_TEST_ASSETSURL,
  },
  fetest: {
    domain: process.env.VUE_APP_FETEST_DOMAIN,
    client_id: process.env.VUE_APP_FETEST_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_FETEST_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_FETEST_APIURL,
    userHubUrl: process.env.VUE_APP_FETEST_USERHUBURL,
    egressUrl: process.env.VUE_APP_FETEST_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_FETEST_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_FETEST_INGRESSWEB,
    audience: process.env.VUE_APP_FETEST_AUDIENCE,
    scope: process.env.VUE_APP_FETEST_SCOPE,
    assetsUrl: process.env.VUE_APP_FETEST_ASSETSURL,
  },
  prod: {
    domain: process.env.VUE_APP_PROD_DOMAIN,
    client_id: process.env.VUE_APP_PROD_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_PROD_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_PROD_APIURL,
    userHubUrl: process.env.VUE_APP_PROD_USERHUBURL,
    egressUrl: process.env.VUE_APP_PROD_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_PROD_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_PROD_INGRESSWEB,
    audience: process.env.VUE_APP_PROD_AUDIENCE,
    scope: process.env.VUE_APP_PROD_SCOPE,
    assetsUrl: process.env.VUE_APP_PROD_ASSETSURL,
  },
  prod_oldipaas: {
    domain: process.env.VUE_APP_PROD_DOMAIN,
    client_id: process.env.VUE_APP_PROD_CLIENT_ID,
    redirect_uri: process.env.VUE_APP_PRODOLDIPAAS_REDIRECT_URI,
    apiUrl: process.env.VUE_APP_PRODOLDIPAAS_APIURL,
    userHubUrl: process.env.VUE_APP_PROD_USERHUBURL,
    egressUrl: process.env.VUE_APP_PRODOLDIPAAS_EGRESSURL,
    ingressScheduledUrl: process.env.VUE_APP_PRODOLDIPAAS_INGRESSSCHED,
    ingressWebUrl: process.env.VUE_APP_PRODOLDIPAAS_INGRESSWEB,
    audience: process.env.VUE_APP_PROD_AUDIENCE,
    scope: process.env.VUE_APP_PROD_SCOPE,
    assetsUrl: process.env.VUE_APP_PROD_ASSETSURL,
  },

};

const urlmap = {
  "localhost:8080": "local",
  "ipaas-ui.sintraoms-test.com": "test_oldipaas",
  "app.stageflowlyze.com": "test",
  "localhost:8700": "fetest",
  "ipaas-ui.sintraoms.com": "prod_oldipaas",
  "app.flowlyze.com": "prod"
};

var env = urlmap[document.location.host];
if (!env) {
  console.error("unable to map config settings");
}
const config = settings[env];

export default config;
