<template>
  <v-card>
    <v-tabs fixed-tabs class="py-2" v-model="tab" tabindex="0" center-active @change="setQueryParamFilter">
      <v-tabs-slider color="primary"></v-tabs-slider>
      <v-tab key="error">Errors</v-tab>
      <v-tab key="logs">Logs</v-tab>
    </v-tabs>
    <v-tabs-items class="vw-100" v-model="tab">
      <v-tab-item key="Error Report" :eager="true">
        <errors ref="errorRef" :inProgressQueue=false @search-logs="searchLogs"></errors>
      </v-tab-item>
      <v-tab-item key="logs" :eager="true" ref="input">
        <logs name="f" ref="logs"></logs>
      </v-tab-item>
    </v-tabs-items>
  </v-card>

</template>

<script>

import logs from "@/components/logs/logs.vue";
import errors from "@/components/errors/errors.vue";

export default {
  name: "error View",
  components: {
    errors: errors,
    logs: logs
  },
  methods: {
    setQueryParamFilter: function (tabId) {
      switch(tabId){
        case 0:
          if (this.$refs.completedRef) {
            console.log("qui")
            this.$refs.errorRef.updateURLWithFiltersEncoded();
          }
          break;
        case 1:
          if (this.$refs.queueRef) {
            this.$refs.logs.updateURLWithFiltersEncoded();
          }
          break;
        default:
          history.pushState(null, '', `${window.location.pathname}`);
          break;
      }
    },
    searchLogs: async function (data) {
      this.tab = 1;

      this.logSearchQuery = { MessageId: data.messageId };

      this.$refs.logs.setSearch(this.logSearchQuery);
      //let that=this;
      //setTimeout(() => that.$refs.logs.setSearch(that.logSearchQuery) , 500);
    },
  },
  mounted: function () {
    window.addEventListener('popstate', this.handleBackButton);
  },
  data: () => ({
    tab: null,
  })
}

</script>



<style scoped>

</style>