<template>
    <v-btn class="mt-3" @click="verifyConnection"><v-icon>mdi-check</v-icon>
      {{ checkLabel }}</v-btn>
</template>

<script>

import {useVuetifyControl} from '@jsonforms/vue2-vuetify';
import {defineComponent} from 'vue';


import {
  useJsonFormsControl
} from '@jsonforms/vue2';
import config from "@/services/config";
import EventBus from "@/plugins/extension";

export default defineComponent({
  name: 'SourceTestConnectionRender',
  beforeMount: async function () {
  },

  setup(props) {
    return useVuetifyControl(
        useJsonFormsControl(props),
        (newValue) => newValue || false
    );
  },
  data: () => ({
    checkLabel: "Check"
  }),
  props: [ 'data','cells', 'uischema', 'schema', 'path', 'enabled','rootSchema','config','id'],
  inject: ['onTestConnection'],
  methods: {
    async verifyConnection() {
      if (this.onTestConnection) {
        let response = await this.onTestConnection("source");
      }
    },
  }
});
</script>
